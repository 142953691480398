<template>
  <v-menu bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on">
        <v-avatar color="primary" size="30">
          <span class="white--text"> {{ firstUserLetter }} </span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center px-2">
          <v-avatar color="primary" class="mb-3">
            <span class="white--text"> {{ firstUserLetter }} </span>
          </v-avatar>
          <h3>{{ user._fullname }}</h3>
          <p class="text-caption mt-1">
            {{ user.user_name }}
          </p>
          <v-divider class="my-3"></v-divider>
          <v-btn depressed rounded text> {{ $t("ui_profile") }} </v-btn>
          <v-divider class="my-3"></v-divider>
          <v-btn depressed rounded text :to="{ name: 'logout' }">
            {{ $t("ui_logout") }}
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      requried: true,
      default: () => ({}),
    },
  },
  computed: {
    firstUserLetter() {
      if (!this.user.user_name) return "U";
      return this.user.user_name.charAt(0).toUpperCase();
    },
  },
};
</script>

<style scoped lang="scss"></style>
