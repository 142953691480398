<template>
  <v-app-bar app color="white">
    <v-app-bar-nav-icon
      v-if="showBarNavIcon"
      @click.stop="$emit('handleNavIconClick')"
    />
    <!-- <v-toolbar-title>Title</v-toolbar-title> -->
    <v-btn
      v-if="showCreateBtn"
      color="accent"
      small
      elevation="1"
      class="ml-5"
      @click="$router.push({ name: 'TaskAdd' })"
    >
      {{ $t("ui_create") }}
    </v-btn>
    <portal-target name="header">
      <!--
      This component can be located anywhere in your App.
      The slot content of the above portal component will be rendered here.
      -->
    </portal-target>
    <v-spacer />
    <Profile :user="user" />
  </v-app-bar>
</template>

<script>
import Profile from "@/components/Profile/Profile.vue";

export default {
  components: { Profile },
  props: {
    showBarNavIcon: {
      type: Boolean,
      default: true,
    },
    showCreateBtn: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss"></style>
