export const menuAdmin = [
  {
    type: "item",
    title: "ui_users",
    subheader: "ui_node_settings",
    to: { name: "adminUsers" },
  },
  {
    type: "item",
    title: "ui_ui",
    subheader: "ui_node_settings",
    to: { name: "adminUI" },
  },
  {
    type: "group",
    title: "ui_statuses",
    subheader: "ui_node_settings",
    to: null,
    items: [
      {
        type: "item",
        title: "ui_statuses",
        to: { name: "adminStatuses" },
      },
      {
        type: "item",
        title: "ui_statuses_progress",
        to: { name: "adminProgress" },
      },
    ],
  },
  {
    type: "group",
    title: "ui_processes",
    subheader: "ui_node_settings",
    to: null,
    items: [
      {
        type: "item",
        title: "ui_processes",
        to: { name: "adminProcesses" },
      },
      {
        type: "item",
        title: "ui_teams",
        to: { name: "adminTeams" },
      },
    ],
  },
  {
    type: "group",
    title: "ui_forms",
    subheader: "ui_node_settings",
    to: null,
    items: [
      {
        type: "item",
        title: "ui_fields",
        to: { name: "adminFields" },
      },
      {
        type: "item",
        title: "ui_forms",
        to: { name: "adminForms" },
      },
    ],
  },
  {
    type: "group",
    title: "ui_boards",
    subheader: "ui_node_settings",
    to: null,
    items: [
      {
        type: "item",
        title: "ui_widgets",
        to: { name: "adminWidgets" },
      },
      {
        type: "item",
        title: "ui_boards",
        to: { name: "adminBoards" },
      },
    ],
  },
  {
    type: "group",
    title: "ui_plugins",
    subheader: "ui_node_settings",
    to: null,
    items: [
      {
        type: "item",
        title: "ui_plugin_store",
        to: { name: "adminPluginStore" },
      },
      {
        type: "item",
        title: "ui_plugin_configs",
        to: { name: "adminPluginConfigs" },
      },
    ],
  },
  {
    type: "item",
    title: "ui_back",
    subheader: null,
    is_active: false,
    to: {
      name: "home",
    },
  },
];
