<template>
  <v-navigation-drawer
    app
    :value="value"
    :color="color"
    dark
    @input="$emit('input', $event)"
  >
    <div class="d-flex align-center pa-5" :class="{ 'justify-center': !logo }">
      <v-img
        v-if="!logo"
        max-width="160"
        contain
        src="../../../assets/logos/Raida-logos_white.png"
      />
      <v-img
        v-else
        :max-width="title ? 44 : 160"
        contain
        :src="logo"
        class="mr-5"
      />
      <span v-if="title" class="white--text"> {{ title }} </span>
    </div>
    <v-list nav dense>
      <template v-for="(item, i) in menu">
        <v-subheader v-if="showSubHeader(item.subheader)" :key="'sh' + i">
          {{ formatLocale(item.subheader) }}
        </v-subheader>
        <component
          :is="getComponent(item.type)"
          :key="i"
          :item="item"
          :class="!item.subheader && 'mt-5'"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import _getComponent from "@/components/layouts/SideNav/scripts/getComponent";

import SideNavItem from "@/components/layouts/SideNav/components/SideNavItem.vue";
import SideNavGroup from "@/components/layouts/SideNav/components/SideNavGroup.vue";
import formatLocale from "@/utils/formaters/formatLocale";

export default {
  components: { SideNavItem, SideNavGroup },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
      default: () => [],
    },
    logo: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  methods: {
    getComponent(type) {
      return _getComponent(type);
    },
    showSubHeader(subheader) {
      if (!Object.prototype.hasOwnProperty.call(this, "_prevSubheader")) {
        if (subheader) {
          this._prevSubheader = subheader;
          return true;
        } else {
          return false;
        }
      } else if (subheader) {
        if (this._prevSubheader === subheader) {
          this._prevSubheader = subheader;
          return false;
        } else {
          this._prevSubheader = subheader;
          return true;
        }
      }
    },
    formatLocale(text) {
      return formatLocale(text);
    },
  },
};
</script>

<style scoped lang="scss"></style>
