<template>
  <v-list-group
    :prepend-icon="item.icon"
    class="font-weight-light"
    color="primary lighten-5"
    :value="item.is_active"
    no-action
    :sub-group="nested"
    @input="$emit('input', $event)"
  >
    <template #activator>
      <v-list-item-title> {{ formatLocale(item.title) }} </v-list-item-title>
    </template>

    <template>
      <component
        :is="getComponent(item.type)"
        v-for="(item, i) in item.items"
        :key="i"
        :item="item"
        nested
      />
    </template>
  </v-list-group>
</template>

<script>
import _getComponent from "@/components/layouts/SideNav/scripts/getComponent";

import SideNavItem from "@/components/layouts/SideNav/components/SideNavItem.vue";
import SideNavGroup from "@/components/layouts/SideNav/components/SideNavGroup.vue";
import formatLocale from "@/utils/formaters/formatLocale";

export default {
  name: "SideNavGroup",
  components: { SideNavItem, SideNavGroup },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getComponent(type) {
      return _getComponent(type);
    },
    formatLocale(text) {
      return formatLocale(text);
    },
  },
};
</script>
