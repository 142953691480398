<template>
  <v-list-item :to="getTo(item.to)" dense>
    <v-list-item-icon v-if="item.icon">
      <v-icon> {{ item.icon }} </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ formatLocale(item.title) }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import formatLocale from "@/utils/formaters/formatLocale";

export default {
  name: "SideNavItem",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getTo(to) {
      let res = {};
      for (let key in to) {
        if (to[key]) {
          res[key] = to[key];
        }
      }
      return res;
    },
    formatLocale(text) {
      return formatLocale(text);
    },
  },
};
</script>
